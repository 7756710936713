*{
  place-items: center;
  p{
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    text-align: center;
  }
}

#words{
  margin:0 auto;
  display:relative;
}

.button {
  background-color:steelblue;
  width: 30%;
  border: none;
  color: white;
  padding: 7px 16px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  position: absolute;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  display: inline-block;
  margin: 2% 35% 0 35%; 
}

h1{
  display: grid;
  padding: 10px;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 2em;
}

@media only screen and (max-width:500px){
  .button {
    width: 80%;
    background-color:royalblue;
    font-size: 12px;
    margin: 2% 10% 0 10%; 
  }

}